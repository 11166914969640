﻿angular.module('app').factory('globalsService', globalsService);

function globalsService() {

    var domain = window.AppHelper.Urls.AnytimebookingDomain;

    return {

        anytimebooking: {
            domain: domain,
            bookingUrl: 'place_booking/preset/',
            categoryUrl: 'category/listAllActive/1/',
            searchUrl: 'search/results3/',
            sitesUrl: 'site/listAll/',
            unitsUrl: 'unit/unitsInCategory/'
        },

        anytimeGlampingCategoryId: '2163',

        anytimeCampingCategoryId: '18609'
    };
}