﻿angular.module('app').component('reviewsResult', {

    bindings: {
        item: '<',
        pageData: '<'
    },

    controllerAs: 'vm',

    controller: function ($filter, reviewsService, toastService) {

        var vm = this;

        vm.formatDate = function (date) {
            return $filter('date')(date, 'd MMMM yyyy');
        };

        vm.hasReply = function () {
            return vm.item.replyIsVisible && (
                vm.item.replyAuthorName && vm.item.replyAuthorRole &&
                vm.item.replyAuthorComment && vm.item.replyAuthorDate);
        };

        vm.reportReview = function () {

            var review = vm.item;

            var message = 'Please confirm that you would like to report the following review: \n\nTitle: ' + review.title + '\nName: ' + review.authorFirstName + ' ' + review.authorLastName + '\nDate: ' + vm.formatDate(review.date);

            if (!window.confirm(message)) return false;

            review.pageId = vm.pageData.pageId;

            //console.log( 'reportReview', review );

            reviewsService.report(review).then(

                function success(response) {
                    toastService.success('Our team appreciate you notifying us.', 'Thank you.');
                },

                function error(response) {
                    toastService.error(response.data.message, 'Something went wrong.');
                });
        };
    },

    templateUrl: './ui/app/reviews/reviews-result.html'
});