﻿angular.module('app').component('anytimeSearch', {

    bindings: {
        onBookingsChange: '&',
        siteId: '@',
        category: '@',
        date: '@',
        period: '@',
        people: '@',
        siteName: '@'
    },

    controllerAs: 'vm',

    controller: function ($filter, anytimebookingService, $element) {

        var vm = this;

        vm.bookings = {
            exact: [],
            close: [],
            notAvailable: []
        };
        vm.criteria = {};

        vm.isSearching = false;
        vm.searchSuccess = false;
        vm.searchError = false;

        var pagesShown = 1;

        var pageSize = 5;

        vm.$onInit = function () {

            var params = {};
            params.date = window.location.href.substr(window.location.href.indexOf('?')).match(/date=(\d{2}\-\d{2}\-\d{4})/);
            params.category = window.location.href.substr(window.location.href.indexOf('?')).match(/category=(\d+)/);
            params.location = window.location.href.substr(window.location.href.indexOf('?')).match(/location=(\d+)/);
            params.lengthOfStay = window.location.href.substr(window.location.href.indexOf('?')).match(/length_of_stay=(\d+)/);
            params.partySize = window.location.href.substr(window.location.href.indexOf('?')).match(/party_size=(\d+)/);
            params.accommodationRedirect = window.location.href.substr(window.location.href.indexOf('?')).match(/accommodationRedirect=([^&]+)/);

            //var criteria = {};
            //criteria.siteId = params.location != null ? params.location[1] : '0';
            //criteria.category = params.category != null ? params.category[1] : '0';
            //criteria.date = params.date != null ? reverseDate( params.date[1] ) : '0';
            //criteria.period = params.lengthOfStay != null ? params.lengthOfStay[1] : '0';
            //criteria.people = params.partySize != null ? params.partySize[1] : '0';

            //criteria = getSampleCriteria();
            //vm.criteria = criteria;

            vm.criteria = {
                siteId: vm.siteId,
                //category: vm.category,
                category: 0,
                date: reverseDate(vm.date),
                period: vm.period,
                people: vm.people
            }

            
        };

        vm.$postLink = function () {

            vm.isSearching = true;

            anytimebookingService.search(vm.criteria).then(

                function success(response) {

                    vm.isSearching = false;
                    vm.searchSuccess = true;
                    console.log('success', response);

                    var bookingsExactResults = response.data.search.exact_results;
                    var bookingsCloseResults = response.data.search.close_results;

                    var bookingsExact = [];
                    var bookingsClose = [];
                    var bookingsNot = [];

                    for (var i = 0; i < bookingsExactResults.length; i++) {
                        var item = createResultViewModel(bookingsExactResults[i]);
                        item.tooltip = getTooltip(item);
                        bookingsExact.push(item);
                    }
                    var exactTypes = bookingsExact.map(result => result.categoryId.toString());
                    for (var i = 0; i < bookingsCloseResults.length; i++) {
                        var item = createResultViewModel(bookingsCloseResults[i]);
                        item.tooltip = getTooltip(item);
                        bookingsClose.push(item);
                        if (!exactTypes.includes(bookingsCloseResults[i].category_id)) {
                            bookingsNot.push(item);
                            exactTypes.push(bookingsCloseResults[i].category_id);
                        }
                    }

                    vm.bookings.exact = bookingsExact;
                    vm.bookings.close = bookingsClose;
                    vm.bookings.notAvailable = bookingsNot;

                    var search = {};
                    search.searchSuccess = vm.searchSuccess;
                    search.searchError = vm.searchError;

                    vm.onBookingsChange()(vm.bookings, vm.criteria, search);
                },

                function error(response) {

                    vm.isSearching = false;
                    vm.searchError = true;
                    //console.error( 'error', response );
                }
            );
        };

        vm.formattedResultsFound = function (value) {
            var pluralised = (parseInt(value, 10) == 1) ? '' : 's';
            return value + ' result' + pluralised + ' found';
        };

        vm.formattedDate = function (date) {
            return $filter('date')(date, 'EEEE d MMMM y');
        };

        function getTooltip(item) {
            let doey = item.categoryName.replace(/\s/g, '').toLowerCase();
            let tooltipParent = document.getElementById(`${doey}Tooltip`);
            let tooltip = false;
            if (tooltipParent) {
                tooltip = document.getElementById(`${doey}Tooltip`).innerHTML;
            }

            return tooltip;
        }

        function reverseDate(str) {

            if (str != undefined) {

                var parts = str.split('-');
                return parts[2] + '-' + parts[1] + '-' + parts[0];
            }
        }

        function toInt(str) {
            var result = parseInt(str, 10);
            result = (isNaN(result)) ? 0 : result;
            return result;
        }

        function createResultViewModel(item) {

            var facilities = {
                wifi: toInt(item.wifi),
                bathroom: toInt(item.bathroom),
                bbq: toInt(item.bbq),
                privateVeranda: toInt(item.private_veranda),
                linen: toInt(item.linen),
                tv: toInt(item.tv),
                storage: toInt(item.storage),
                electricity: toInt(item.electricity),
                beds: toInt(item.bedrooms),
                heating: toInt(item.heating),
                parking: toInt(item.parking)
            };

            return {
                id: toInt(item.id),

                siteId: toInt(item.site_id),
                siteName: item.site_name,

                categoryName: item.category_name,
                categoryOtherName: item.name,
                categoryId: toInt(item.category_id),

                unitId: item.unit_id,
                facilities: facilities,

                startDate: item.day,
                endDate: item.end_day,

                duration: toInt(item.period),
                occupancy: toInt(item.occupancy),
                description: item.description_reduced,
                price: item.rate,
                minPrice: item.min_price,
                maxPrice: item.max_price,
                tooltipId: item.category_name.replace(/\s/g, '').toLowerCase()
            };
        }

        vm.paginationLimit = function (data) {
            return pageSize * pagesShown;
        };

        vm.hasMoreItemsToShow = function () {
            return pagesShown < (vm.bookings.close.length / pageSize);
        };

        vm.showMoreItems = function () {
            pagesShown = pagesShown + 1;
        };

        vm.updateFoundation = function () {
            $timeout(function () {
                $element.find('[data-magellan]').foundation('reflow');
            }, 200);
        }

        //function getSampleCriteria() {

        //    var today = new Date();
        //    var dd = today.getDate(); dd = ( dd < 10 ) ? '0' + dd : dd;
        //    var mm = today.getMonth() + 1; mm = ( mm < 10 ) ? '0' + mm : mm;
        //    var yyyy = today.getFullYear();

        //    var criteria = {};
        //    criteria.siteId = 44;
        //    criteria.category = 0;
        //    criteria.date = yyyy + '-' + mm + '-' + dd;
        //    criteria.period = 2;
        //    criteria.people = 1;

        //    return criteria;
        //}
    },

    templateUrl: './ui/app/anytime-search/anytime-search.html'
});