﻿angular.module('app').component('signPostBookingModal', {

    bindings: {
        type: '@',
        guests: '<',
        duration: '<',
        pageId: '<',
        anyTimeId: '<',
        camcSiteId: '@',
        siteName: '@',
        siteLocation: '@'
    },

    controllerAs: 'vm',

    controller: function ($filter, searchService, $window) {

        var vm = this;
        vm.disabled = true;
        vm.showError = false;

        vm.criteria = {
            type: '',
            guests: 0,
            duration: 0
        };

        vm.guestSelection = [
            { value: 0, text: "Guests" },
            { value: 1, text: "1 Guest" },
            { value: 2, text: "2 Guests" },
            { value: 3, text: "3 Guests" },
            { value: 4, text: "4 - 6 Guests" }
        ]
        vm.durationSelection = [
            { value: 0, text: "Duration" },
            { value: 2, text: "2 Nights" },
            { value: 3, text: "3 Nights" },
            { value: 4, text: "4 Nights" },
            { value: 5, text: "5 Nights" },
            { value: 6, text: "6 Nights" },
            { value: 7, text: "7 Nights" },
            { value: 8, text: "8 Nights" },
            { value: 9, text: "9 Nights" },
            { value: 10, text: "10 Nights" },
            { value: 11, text: "11 Nights" },
            { value: 12, text: "12 Nights" },
            { value: 13, text: "13 Nights" },
            { value: 14, text: "14 Nights" },
            { value: 15, text: "15 Nights" },
            { value: 16, text: "16 Nights" },
            { value: 17, text: "17 Nights" },
            { value: 18, text: "18 Nights" },
            { value: 19, text: "19 Nights" },
            { value: 20, text: "20 Nights" },
            { value: 21, text: "21 Nights" },
        ]

        vm.submitToAnyTime = submitToAnyTime;

        vm.$onChanges = function (changesObj) {

            if (changesObj.guests && changesObj.guests.currentValue) {

                vm.criteria.guests = changesObj.guests.currentValue;
            }

            if (changesObj.duration && changesObj.duration.currentValue) {

                vm.criteria.duration = changesObj.duration.currentValue;
            }

            if (changesObj.anyTimeId && changesObj.anyTimeId.currentValue) {

                vm.criteria.anyTimeId = changesObj.anyTimeId.currentValue;
            }

            if (changesObj.pageId && changesObj.pageId.currentValue) {

                vm.criteria.pageId = changesObj.pageId.currentValue;
            }
        };

        vm.$onInit = function () {

            vm.criteria.type = vm.type;
            vm.criteria.camcSiteId = vm.camcSiteId;
        };

        function submitToAnyTime() {

            //console.log(vm.signForm.$invalid);

            if (vm.signForm.$invalid || vm.criteria.duration == 0 || vm.criteria.guests == 0) {

                vm.showError = true;
            }
            else {
                vm.showError = false;
                $('#booking-sign-post-popup').foundation('close');
                $('#glamping-transfer').foundation('open');
                $window.location.href = "/locations" + "?type=" + vm.criteria.type + "&locationId=" + vm.criteria.pageId + "&region=" + vm.criteria.region + "&regionId=" + "&date=" + vm.criteria.date + "&duration=" + vm.criteria.duration + "&guests=" + vm.criteria.guests + "&anyTimeBookingId=" + vm.criteria.anyTimeId + "&camcSiteId=" + vm.criteria.camcSiteId + "#accommodation";
            }
        }

        vm.changeOption = function (option) {

            if (option < "1") {

                vm.disabled = true;
            }
            else {
                vm.disabled = false;
            }
        }
    },

    templateUrl: './ui/app/components/sign-post-booking-modal.html'
});