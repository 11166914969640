﻿angular.module('app').factory('searchService', searchService);

function searchService($http) {

    var url = '/umbraco/api/Search/';

    return {

        getAllLocations: getAllLocations,
        getLocationsByCriteria: getLocationsByCriteria,
        getRegions: getRegions,
        getRegionLocations: getRegionLocations

    };

    function getAllLocations() {
        return $http.get(url + 'getAllLocations');
    }


    function getLocationsByCriteria(searchModel) {
    	var accomodationTypesArray = $.makeArray(searchModel.accomodationTypes);
    	var regionsArray = $.makeArray(searchModel.regions);
    	return $http.get(url + 'getLocationByCriteria?accomodationTypes=' + accomodationTypesArray.join('|') + '&regions=' + regionsArray.join('|'));
    }

    function getRegions() {
        return $http.get(url + 'getRegions');
    }

    function getRegionLocations(type) {

        return $http.get(url + 'getRegionLocationsMenu/' +"?type=" + type );
    }
}