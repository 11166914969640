﻿angular.module('app').factory('modalService', modalService);

function modalService() {

    var modals = []; // array of modals on the page

    return {

        Add: Add,
        Remove: Remove,
        Open: Open,
        Close: Close
    };

    function Add(modal) {
        // add modal to array of active modals
        modals.push(modal);
    }

    function Remove(id) {
        // remove modal from array of active modals
        var modalToRemove = _.findWhere(modals, { id: id });
        modals = _.without(modals, modalToRemove);
    }

    function Open(id) {
        // open modal specified by id
        var modal = _.findWhere(modals, { id: id });

        console.log('found modal', modal, 'ID:', id);
        modal.open();
    }

    function Close(id) {
        // close modal specified by id
        var modal = _.findWhere(modals, { id: id });
        modal.close();
    }
}