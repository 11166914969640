﻿angular.module('app.core', [

    'app.core.directives',
    'app.core.helpers',
    'app.core.services'

]);

require('./directives/directives.module');
require('./helpers/helpers.module');
require('./services/services.module');