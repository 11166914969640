﻿$(function () {

    if (typeof window.matchMedia != "undefined" || typeof window.msMatchMedia != "undefined") {

        if (window.matchMedia('(min-width: 64.0625em)').matches) {
            var bgElem = $('.signpost-bg');
            if (bgElem.length > 0) {
                $(window).scroll(function () {
                    var scrolledY = $(window).scrollTop();
                    bgElem.css('background-position', '50% calc(50% + ' + ((scrolledY)) + 'px)');
                });
            }
        }

    }

});