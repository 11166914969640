﻿(function () {
    'use strict';
    angular
        .module('app')
        .factory('http_interceptor', http_interceptor);

    http_interceptor.$inject = ['$rootScope', '$q'];

    function http_interceptor($rootScope, $q) {

        //console.log('interceptor firing');
        var numLoadings = 0;

        return {

            request: function (config) {

                numLoadings++;

                // Show loader
                //console.log('show loader');
                $rootScope.$broadcast("loader_show");

                return config || $q.when(config)
            },

            response: function (response) {

                if ((--numLoadings) === 0) {
                    // Hide loader
                    //console.log('hide loader');
                    $rootScope.$broadcast("loader_hide");
                }

                return response || $q.when(response);
            },

            responseError: function (response) {

                if (!(--numLoadings)) {
                    // Hide loader
                    $rootScope.$broadcast("loader_hide");
                }

                return $q.reject(response);
            }
        }
    }
})();