﻿(function navToggle() {
    $('#primary-nav li.has-subnav > span').click(function (e) {

        if (Foundation.MediaQuery.current == 'large') return;

        e.preventDefault();

        var $parent = $(this).parents('.has-subnav');

        if ($parent.hasClass('menu-open')) {
            $parent.removeClass('menu-open');
        } else {
            $('#primary-nav li.has-subnav').removeClass('menu-open');
            $parent.addClass('menu-open');
        }
    });

    if ("ontouchstart" in document.documentElement)
    {
        if ($(window).width() > 1024) {
            $('#primary-nav li.has-subnav a').click(function (e) {

                var $this = $(this);

                e.stopPropagation();
                if (!$this.parents('#primary-nav li').hasClass('is-open')) {
                    e.preventDefault();
                    $this.parents('#primary-nav li').addClass('is-open');
                }
            });
        } 
    }
   
})();