﻿angular.module('app').factory('lateAvailabilityService', lateAvailabilityService);

function lateAvailabilityService($http) {

    var url = '/umbraco/api/LateAvailability/';

    return {

        getLateAvailability: getLateAvailability
    };

    function getLateAvailability(startDate) {

        return $http.get(url + 'getLateAvailability/' + "?start=" + startDate);
    }
}