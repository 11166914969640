﻿var app = angular.module('app', ['ngSanitize', 'app.core', 'ngAnimate', ]).config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('http_interceptor')
}]);

/**
* Require.
*/

// Modules
require('angular-sanitize');
require('./core/core.module');

// Filters

require('./filters/html-filter.js');
require('./filters/exclude-from.js');

// Controllers

require('./controllers/root.controller');
require('./controllers/search.controller');
require('./controllers/location.controller');

// Components
require('./components/locations-listing-component.js');
require('./components/search-bar-component.js');
require('./components/thin-search-bar-component.js');
require('./anytime-search/anytime-search.component');
require('./anytime-search/anytime-search-result.component');
require('./components/search-and-book-modal.js');
require('./components/sign-post-booking-modal.js');
require('./components/loading-directive.js');
require('./components/Mobile.js');
require('./components/social-bar.js');

require('./reviews/reviews.component');
require('./reviews/reviews-summary.component');
require('./reviews/reviews-result.component');
require('./reviews/add-review.component');

require('./reviews/add-review-page.component');
require('./late-availability/late-availability-component');
require('./late-availability/late-availability-component-single');
// Directives

require('./reviews/trip-advisor.directive');
require('./reviews/star-rating.directive');
require('./components/modal.directive.js')

// Services

require('./services/modal.service');
require('./services/anytimebooking.service');
require('./services/globals.service');
require('./services/reviews.service');
require('./services/search.service');
require('./services/http-interceptor.js');
require('./services/late-availability.service');

/**
* Configure.
*/

/* @ngInject */
//var config = function () {

//};
//app.config( config );