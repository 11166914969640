﻿angular.module('app').component('searchAndBookModal', {

    bindings: {
        departureDate: '@',
        arrivalDate: '@',
        siteId: '@',
        siteName: '@',
        siteLocation: '@',
        siteIbeKey: '@'
    },

    controllerAs: 'vm',

    controller: function ($filter, searchService, $window) {

        var vm = this;
        vm.disabled = true;
        vm.search = {};

        vm.isFormFieldInvalid = isFormFieldInvalid;
        vm.submitToCAMC = submitToCAMC;
        vm.isFormSubmitted = false;

        vm.showDatePicker = false;

        vm.$onInit = function () {

            if (vm.arrivalDate == "01/01/0001" || vm.arrivalDate == "1/1/0001") {

                vm.arrivalDate = "";
                vm.departureDate = "";

                vm.showDatePicker = true;
            }

            vm.search.arrivalDate = vm.arrivalDate;
            vm.search.departureDate = vm.departureDate;
        }

        function submitToCAMC() {

            //console.log('dates passed in', vm.arrivalDate, vm.departureDate);

            //console.log('set dates', vm.search.arrivalDate, vm.search.departureDate);

            vm.isFormSubmitted = true;

            if (vm.camcForm.$invalid || vm.search.adults == 0) {
                return false;
            }

            var formattedArrivalDate = moment(moment(vm.search.arrivalDate, 'DD-MM-YYYY')).format('YYYY-MM-DD');

            var formattedDepartureDate = moment(moment(vm.search.departureDate, 'DD-MM-YYYY')).format('YYYY-MM-DD');

            //console.log('formatted dates', formattedArrivalDate, formattedDepartureDate

            var activePin = vm.siteIbeKey;

            // New
            var camcNewUrl = "https://bookings.caravanclub.co.uk/sites?start=" + formattedArrivalDate + "&end=" + formattedDepartureDate + "&zoomLevel=9&activePin=" + activePin + "&eventType=3&types=1&types=2" + "&adults=" + vm.search.adults + "&children=" + vm.search.children + "&infants=" + vm.search.infants;

            // Old
            var camcurl = 'https://www.caravanclub.co.uk/ecommerce/uksites/camping/availability?searchTerm=SITEID-' + vm.siteId + "&adults=" + vm.search.adults + "&children=" + vm.search.children + "&infants=" + vm.search.infants + "&arrivalDate=" + formattedArrivalDate + "&departureDate=" + formattedDepartureDate;

            //console.log('CAMC QUERY STRING:', camcurl);

            $('#booking-transfer').foundation('open');

            // Change URL here
            $window.location = camcNewUrl;
        }

        vm.changeOption = function (option) {

            if (option < "1") {

                vm.disabled = true;
            }
            else {
                vm.disabled = false;
            }
        }

        vm.changeColClass = function (showDate) {

            vm.colClass = "4";

            if (showDate) {

                vm.colClass = "3";
            }

            return "column medium-" + vm.colClass
        }

        function isFormFieldInvalid(fieldName) {

            return vm.camcForm[fieldName].$invalid && vm.isFormSubmitted;
        }
    },

    templateUrl: './ui/app/components/search-and-book-modal.html'
});