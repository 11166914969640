﻿angular.module('app').controller('RootController', RootController);

function RootController() {

    var vm = this;

    vm.pageData = {};

    vm.init = function (pageData) {
        vm.pageData = pageData;
    };
}