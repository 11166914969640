﻿angular.module('app').controller('LocationController', LocationController);

function LocationController($element, $timeout, modalService) {

    var vm = this;

    vm.bookings = {};
    vm.criteria = {};

    vm.reviews = [];

    vm.openModal = openModal;
    vm.closeModal = closeModal;

    vm.onBookingsChange = function (bookings, criteria, search) {

        vm.bookings = bookings;
        vm.criteria = criteria;

        // TODO: Angularify.

        var elDynamic = document.getElementById('accommodation-results-dynamic');
        var elStatic = document.getElementById('accommodation-results-static');

        if (search.searchSuccess) {
            angular.element(elDynamic).removeClass('hide');
            //angular.element( elStatic ).hide(); // Uncomment for production.
        }

        updateFoundation();
    };

    vm.onReviewsChange = function (reviews) {
        vm.reviews = reviews;
        updateFoundation();
    };

    function updateFoundation() {
        $timeout(function () {
            $element.find('[data-magellan]').foundation('reflow');
        }, 200);
    }

    function openModal(id) {

        modalService.Open(id);
    }

    function closeModal(id) {
        modalService.Close(id);
    }
}