﻿(function () {
    angular.module('app')
        .filter('excludefrom', [, function () {

            return function (array, type) {

                var myArray = array;

                console.log('filtering', array, type)

                if (type == false) {

                    for (var i = myArray.length - 1; i >= 0; --i) {
                        if (myArray[i].value == 1) {
                            myArray.splice(i, 1);
                        }
                    }
                }

                console.log('myArray', myArray, type)

                return myArray;
            }
        }]);
})();