﻿angular.module('app').directive('tripAdvisor', TripAdvisorDirective);

function TripAdvisorDirective($compile, $timeout) {

    return {
        restrict: 'E',
        scope: {
            taWidget: '<',
        },
        link: link
    };

    function link(scope, element, attrs) {

        //console.log('ta widget', scope.taWidget);

        var compiled = $compile(scope.taWidget)(scope);
        element.html(compiled);
    }
}