﻿(function () {
    angular.module('app').component('lateAvailability', {

        bindings: {
            start: '@'
        },
        templateUrl: './ui/app/late-availability/late-availability.html',
        controller: controller,
        controllerAs: 'vm'
    });

    controller.$inject = ['$filter', 'globalsService', 'lateAvailabilityService', '$element', '$timeout', '$window'];

    function controller($filter, globalsService, lateAvailabilityService, $element, $timeout, $window) {

        var vm = this;

        vm.startDate = null;

        vm.sites = [];
        vm.dateHeading = [];

        vm.selectedSortBy = "";

        vm.sortBy = [
            { value: "", text: "Sort By" },
            { value: "name", text: "Campsite Name A-Z" },
            { value: "-name", text: "Campsite Name Z-A" }
            
        ];

        vm.next = function () {
            vm.startDate = vm.startDate.addDays(7);
            vm.getLateAvailability();
            //console.log("start", vm.startDate);
            //console.log("startISO", vm.startDate.toISOString());

        };

        vm.getBookingUrl = function (site, availability) {
            if (!site || !availability || !site.bookingUrl) return "";
            //console.log(moment(new Date(availability.date)).format('DD-MM-YYYY'));
            var date = moment(new Date(availability.date)).format('DD-MM-YYYY');
            var bookingUrl = site.bookingUrl + '?date=' + date + '&duration=2&guests=1&anyTimeBookingId=' + site.id + '&camcSiteId=' + site.bookingPrefix;
            return bookingUrl;
        };

        vm.previous = function () {
            if (new Date(vm.startDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) return;
            vm.startDate = vm.startDate.addDays(-7);
            vm.getLateAvailability();
            
        };

        vm.isPreviousDisabled = function () {
            return new Date(vm.startDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0);
        };

        vm.getLateAvailability = function () {
            //console.log(vm.startDate);
            lateAvailabilityService.getLateAvailability(vm.startDate.toISOString())
                .then(function (result) {
                    vm.sites = result.data;
                    vm.getDateHeading();
                    return result.data;
                });
        };

        vm.getClassForAvailability = function (level) {
            switch (level) {
                case 0:
                    return "book book--low";
                case 1:
                    return "book";
                case 2:
                    return "full";
                default:
                    return "";
            }
        };

        vm.getDateHeading = function () {
            vm.dateHeading = [];
            var day = vm.startDate;
            for (var i = 0; i < 7; i++) {
                var dayString = day.toString().split(' ');
                vm.dateHeading.push({
                    dayOfWeek: dayString[0],
                    month: dayString[1],
                    day: dayString[2]
                });
                day = day.addDays(1);
            }
        };


        vm.$onChanges = function (changesObj) {

            if (changesObj.start && changesObj.start.currentValue) {
                //console.log("start date from server", changesObj.start.currentValue);
                vm.startDate = new Date(changesObj.start.currentValue);

                vm.getLateAvailability();
            }
        };

        vm.$onInit = function () {
            
        };

        
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        var dateMoment = moment(date);
        var momentAdded = dateMoment.add(days, 'days');
        if (momentAdded.isDST()) {
            momentAdded = momentAdded.add(1, 'hours');
        }
        var momentToDate = momentAdded.toDate();
        return momentToDate;
    };
})();