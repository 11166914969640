﻿angular.module('app.core.helpers').directive('convertToNumber', convertToNumber);

// Credit: https://weblog.west-wind.com/posts/2015/May/21/Angular-Select-List-Value-not-binding-with-Static-Values

function convertToNumber() {

    return {

        require: 'ngModel',

        link: function (scope, element, attrs, ngModel) {

            ngModel.$parsers.push(function (val) {
                return parseInt(val, 10);
            });

            ngModel.$formatters.push(function (val) {
                return '' + val;
            });
        }
    }
} 