﻿angular.module('app').component('reviews', {

    bindings: {
        pageData: '<',
        onReviewsChange: '&',
        taUrl: '@',
        taWidget: '@'
    },

    controllerAs: 'vm',

    controller: function ($timeout, paginationService, reviewsService) {

        var vm = this;

        vm.pager = {};
        vm.reviews = [];

        vm.resultsPerPage = 5;
        vm.fetchingReviewsFailed = false;

        vm.$postLink = function () {

            var pageId = vm.pageData.pageId;

            reviewsService.getAll(pageId).then(

                function success(response) {

                    vm.reviews = response.data.reviews;
                    vm.resultsPerPage = response.data.reviewsPerPage;
                    vm.setPage(1);
                    vm.onReviewsChange()(vm.reviews);
                    new Foundation.Equalizer($('.js-equalizer'));
                },

                function error(response) {

                    vm.fetchingReviewsFailed = true;
                });
        };

        vm.getReviewsHeading = function () {
            var suffix = (vm.reviews.length == 1) ? '' : 's';
            return vm.reviews.length + ' Review' + suffix;
        };

        vm.isReviewsEmpty = function () {
            return vm.reviews.length === 0 && !vm.fetchingReviewsFailed;
        };

        vm.isFirstPageDisplayed = function () {
            return vm.pager.currentPage == 1;
        }

        vm.isLastPageDisplayed = function () {
            return vm.pager.currentPage == vm.pager.totalPages;
        }

        vm.didFetchingReviewsFail = function () {
            return vm.fetchingReviewsFailed;
        };

        vm.showPagination = function () {
            return vm.reviews.length > vm.resultsPerPage;
        };

        vm.previousPage = function () {
            vm.setPage(vm.pager.currentPage - 1);
        };

        vm.nextPage = function () {
            vm.setPage(vm.pager.currentPage + 1);
        };

        vm.setPage = function (page) {
            var isFirstReview = (page == 1 && vm.pager.totalPages == 0);
            if ((page < 1 || page > vm.pager.totalPages) && !isFirstReview) {
                return;
            }
            vm.pager = paginationService.getPager(vm.reviews.length, page, vm.resultsPerPage);
            vm.items = vm.reviews.slice(vm.pager.startIndex, vm.pager.endIndex + 1);
        };

        vm.onCreateReview = function (review) {
            vm.reviews.unshift(review);
            vm.setPage(1);
            vm.onReviewsChange()(vm.reviews);
        };
    },

    templateUrl: './ui/app/reviews/reviews.html'
});