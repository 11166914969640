﻿CookieConsent = (function () {
    var callbacks = {}

    function init (config) {
        for (var i in config.optionalCookies) {
            var name = config.optionalCookies[i].name;
            (function (name) {
                config.optionalCookies[i].onAccept = function () {
                    if (typeof (dataLayer) !== 'undefined') {
                        dataLayer.push({ 'event': name + '_consent_given' });
                    }
                    for (var i in callbacks[name].accept) {
                        callbacks[name].accept[i]()
                    }
                }

                config.optionalCookies[i].onRevoke = function (ssss) {
                    if (typeof (dataLayer) !== 'undefined') {
                        dataLayer.push({ 'event': name + '_consent_revoked' });
                    }
                    for (var i in callbacks[name].revoke) {
                        callbacks[name].revoke[i]()
                    }
                }
            })(name);
        }

        CookieControl.load(config);
    }

    function addCallback(category, action, callback) {
        if (typeof (callbacks[category]) === 'undefined') {
            callbacks[category] = { accept: [], revoke: [] };
        }
        callbacks[category][action].push(callback);
    }

    function addAcceptCallback (category, callback) {
        addCallback(category, 'accept', callback)
    }

    function addRevokeCallback(category, callback) {
        addCallback(category, 'revoke', callback)
    }

    return {
        init: init,
        addAcceptCallback: addAcceptCallback,
        addRevokeCallback: addRevokeCallback
    }
})()
