﻿angular.module('app').factory('reviewsService', reviewsService);

function reviewsService($http) {

    var url = '/umbraco/api/Reviews/';

    return {

        getAll: getAll,
        create: create,
        report: report,
        getAccomodationTypes: getAccomodationTypes
    };

    function getAll(pageId) {
        return $http.get(url + 'GetAll/' + pageId);
    }

    function create(review) {
        return $http.post(url + 'CreateReview', review);
    }

    function report(review) {
        return $http.post(url + 'ReportReview', review);
    }

    function getAccomodationTypes() {
        return $http.get(url + 'GetAccomodationTypes');
    }
}