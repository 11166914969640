﻿angular.module('app').component('addReview', {

    bindings: {
        pageData: '<',
        onCreateReview: '&'
    },

    controllerAs: 'vm',

    controller: function ($timeout, toastService, reviewsService) {

        var vm = this;

        vm.formData = {};

        vm.maxength = 2000;

        vm.accomodationTypes = [];
        vm.accomodationTypeOptions = [];

        vm.$onInit = function () {
            resetForm();

            reviewsService.getAccomodationTypes().then(

                function success(response) {
                    vm.accomodationTypes = response.data;
                    var accomodationTypeOptions = [];
                    for (var i = 0; i < response.data.length; i++) {
                        var id = i + 1;
                        var name = response.data[i];
                        accomodationTypeOptions.push({ id: id, name: name });
                    }
                    vm.accomodationTypeOptions = accomodationTypeOptions;
                },

                function error(response) {
                }
            );
        }

        vm.$postLink = function () {
            // Rebind Foundation so modal works after compilation.
            angular.element(document).foundation();
        }

        vm.onStarRatingEdit = function (rating) {

            if (!angular.isNumber(rating)) return;

            if (rating < 1)
                rating = 1;
            else if (rating > 5)
                rating = 5;

            $timeout(function () {
                vm.formData.rating = rating;
            }, 0);
        }

        vm.onSubmitReview = function () {

            var form = vm.formAddReview;

            vm.isFormSubmitted = true;

            // Validate form.
            var response = grecaptcha.getResponse();
            if (response.length == 0) {
                toastService.error('Please complete the reCAPTCHA check.', 'Something went wrong.');
                return false;
            }
            vm.formData.recaptchaToken = grecaptcha.getResponse();

            if (form.$invalid || vm.formData.accomodationType == 0) {
                return false;
            }

            // Close modal window.
            var modal = document.getElementById('add-review-modal');
            angular.element(modal).foundation('close');

            // Create.
            vm.formData.pageId = vm.pageData.pageId;

            reviewsService.create(vm.formData).then(

                function success(response) {

                    var accomodation = response.data.accomodationType;
                    response.data.accomodationType = vm.accomodationTypes[accomodation - 1]; // Convert int to string name.

                    //vm.onCreateReview()( response.data ); // Uncomment to instantly update page with new review.
                    toastService.success('It is awaiting moderation.', 'Thank you for your review!');
                    resetForm();
                },

                function error(response) {
                    toastService.error(response.data.message, 'Something went wrong.');
                    resetRecaptcha();
                }
            );
        }

        vm.isFormFieldInvalid = function (fieldName) {
            return vm.formAddReview[fieldName].$invalid && vm.isFormSubmitted; //&& !vm.formAddReview[fieldName].$pristine;
        }

        function resetForm() {

            $timeout(function () {
                vm.isFormSubmitted = false;
                vm.formAddReview.$setPristine();
                vm.formData.title = vm.pageData.title;
                vm.formData.authorFirstName = vm.pageData.authorFirstName;
                vm.formData.authorLastName = vm.pageData.authorLastName;
                vm.formData.accomodationType = 0;
                vm.formData.rating = 5;
                vm.formData.comment = vm.pageData.comment;
                vm.formData.authorEmail = vm.pageData.authorEmail;
                resetRecaptcha();
            }, 0);
        }

        function resetRecaptcha() {

            if (angular.isUndefined(grecaptcha))
                return;

            grecaptcha.reset();
        }
    },

    templateUrl: './ui/app/reviews/add-review.html'
});