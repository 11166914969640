﻿(function () {
    'use strict';

    angular
        .module('app')
        .directive('loadingDirective', loadingDirective);

    loadingDirective.$inject = ['$window'];

    function loadingDirective($window) {
        // Usage:
        //     <loading_directive></loading_directive>
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'EA'
        };
        return directive;

        function link(scope, element, attrs) {

            scope.$on('loader_show', function (event, args) {

                scope.loading = true;
            });

            scope.$on('loader_hide', function (event, args) {

                scope.loading = false;
            });
        }
    }
})();