﻿angular.module('app.core.services').factory('paginationService', paginationService);

// Credit: http://jasonwatmore.com/post/2016/01/31/angularjs-pagination-example-with-logic-like-google

function paginationService() {

    return {

        getPager: getPager
    };

    // Credit: Underscore.js
    function range(start, stop, step) {
        if (stop == null) {
            stop = start || 0;
            start = 0;
        }
        step = step || 1;

        var length = Math.max(Math.ceil((stop - start) / step), 0);
        var range = Array(length);

        for (var idx = 0; idx < length; idx++ , start += step) {
            range[idx] = start;
        }

        return range;
    };

    function getPager(totalItems, currentPage, pageSize) {

        currentPage = currentPage || 1;
        pageSize = pageSize || 3;

        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // Less than 10 total pages so show all.
            startPage = 1;
            endPage = totalPages;
        }
        else {
            // More than 10 total pages so calculate start and end pages.
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            }
            else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            }
            else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // Calculate start and end item indexes.
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // Create an array of pages to ng-repeat in the pager control.
        var pages = range(startPage, endPage + 1);

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}