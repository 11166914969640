﻿angular.module('app').factory('anytimebookingService', anytimebookingService);

function anytimebookingService($http, globalsService) {

    var paths = globalsService.anytimebooking;

    return {
        search: search
    };

    function search(criteria) {

        console.log('criteria', criteria);
        var url = paths.domain + paths.searchUrl + '/' + criteria.date + '/' + criteria.period + '/' + criteria.people + '/0/0/1/3/' + criteria.category + '/0/json/' + criteria.siteId;
        console.log(url);
        return $http.get(url);
    }
}