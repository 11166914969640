﻿$(function () {

    var showMoreText = {

        init: function () {

            this.options = {
                start: '[more-start]',
                end: '[more-end]',
                linkText: 'Show more'
            };

            this.convertMarkers();
        },

        convertMarkers: function () {

            // Don't convert CMS backend content.
            if (window.AppHelper.Content.IsBackEnd === "True") return;

            var options = this.options;

            var $start = this.find(options.start);
            var $end = this.find(options.end);

            // Check if anything to convert.
            if (!$start.length && !$end.length) return;

            // Check for mismatched markers.
            if ($start.length != $end.length) {
                $start.css({ 'color': 'red' });
                $end.css({ 'color': 'red' });
                console.error('showMoreText: Have you correctly paired your markers?', options.start + ':' + $start.length, options.end + ':' + $end.length);
                return;
            }

            var processComplete = $.Deferred();

            $.when(processComplete).done(function () {

                // Remove markers.
                $start.remove();
                $end.remove();
            });

            // Process markers.

            $start.each(function (index) {

                $(this)
                    .before('<p><a id="js-showmore-link-' + index + '" onclick="AppHelper.showMoreText(' + index + ')">' + options.linkText + ' <i class="far fa-plus"></i></a></p>')
                    .nextUntil($end[index])
                    .wrapAll('<span id="js-showmore-block-' + index + '" class="hide"></span>');

                if (index == $end.length - 1) {
                    processComplete.resolve();
                }
            });
        },

        find: function (text, selector) {

            var sel = selector || 'p';

            return $(sel).filter(function () {
                var result = $.trim($(this).text());
                var isMatch = result === text;
                //console.log(result, isMatch);
                return isMatch;
            });
        }
    };

    window.AppHelper.showMoreText = function (index) {

        $('#js-showmore-link-' + index).parent().remove();
        $('#js-showmore-block-' + index).removeClass('hide');
    };

    showMoreText.init();
});
