﻿angular.module('app.core.services').factory('toastService', toastService);

function toastService(toaster) {

    return {

        info: info,
        success: success,
        error: error
    };

    function info(message, title) {

        var params = formatMessage(message);
        params.type = 'info';
        params.title = (angular.isString(title)) ? title : 'Info';

        toast(params);
    }

    function success(message, title) {

        var params = formatMessage(message);
        params.type = 'success';
        params.title = (angular.isString(title)) ? title : 'Success';

        toast(params);
    }

    function error(message, title) {

        var params = formatMessage(message);
        params.type = 'error';
        params.title = (angular.isString(title)) ? title : 'Error';
        params.body = (params.body !== '') ? params.body : 'An unknown error occurred.';

        toast(params);
    }

    function toast(params) {
        toaster.pop(params);
    }

    function formatMessage(obj) {

        obj = (null === obj || 'undefined' === typeof obj) ? {} : obj;
        if ('string' === typeof obj) {
            obj = { body: obj };
        }
        obj.body = (null === obj.body || 'undefined' === typeof obj.body)
            ? ''
            : obj.body;

        return obj;
    };
}