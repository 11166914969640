﻿(function () {
    angular.module('app').component('thinSearchBarComponent', {

        bindings: {
            isHomePage: '<',
            pageUrl: '@',
            location: '@',
            data: '=',
            durationStay: '<',
            noGuests: '<',
            type: '<',
            pageId: '<',
            thin: '@',
            crit: '='
        },
        templateUrl: './ui/app/components/thin-search-bar-component.html',
        controller: controller,
        controllerAs: 'vm'
    });

    controller.$inject = ['$filter', 'globalsService', 'searchService', '$element', '$timeout', '$window'];

    function controller($filter, globalsService, searchService, $element, $timeout, $window) {

        var vm = this;

        vm.siteType = "";

        vm.locations = [];
        vm.maxDuration = 21;

        vm.showMenu = false;
        vm.campingIsActive = false;
        vm.isHomePage = false;
        vm.isFormSubmitted = false;
        vm.isThin = true;

        vm.criteria = {
            type: '',
            guests: 0,
            duration: 0
        };

        vm.typeOptions = [
            { name: 'Accommodation', value: '0' },
            { name: 'Glamping', value: globalsService.anytimeGlampingCategoryId },
            { name: 'Camping', value: globalsService.anytimeCampingCategoryId }
        ];

        vm.guests = [
            { value: 0, text: "Guests" },
            { value: 1, text: "1 Guest" },
            { value: 2, text: "2 Guests" },
            { value: 3, text: "3 Guests" },
            { value: 4, text: "4 - 6 Guests" }
        ];

        vm.duration = [
            { value: 0, text: "Duration" },
            //{ value: 1, text: "1 Night" },
            { value: 2, text: "2 Nights" },
            { value: 3, text: "3 Nights" },
            { value: 4, text: "4 Nights" },
            { value: 5, text: "5 Nights" },
            { value: 6, text: "6 Nights" },
            { value: 7, text: "7 Nights" },
            { value: 8, text: "8 Nights" },
            { value: 9, text: "9 Nights" },
            { value: 10, text: "10 Nights" },
            { value: 11, text: "11 Nights" },
            { value: 12, text: "12 Nights" },
            { value: 13, text: "13 Nights" },
            { value: 14, text: "14 Nights" },
            { value: 15, text: "15 Nights" },
            { value: 16, text: "16 Nights" },
            { value: 17, text: "17 Nights" },
            { value: 18, text: "18 Nights" },
            { value: 19, text: "19 Nights" },
            { value: 20, text: "20 Nights" },
            { value: 21, text: "21 Nights" },
        ];

        vm.glampingId = globalsService.anytimeGlampingCategoryId;
        vm.campingId = globalsService.anytimeCampingCategoryId;

        // functions

        vm.selectOption = selectOption;
        vm.toggleMenu = toggleMenu;
        vm.filterDuration = filterDuration;
        vm.isFormFieldInvalid = isFormFieldInvalid;

        vm.$onChanges = function (changesObj) {

            updateFoundation();

            if (changesObj.durationStay && changesObj.durationStay.currentValue) {

                vm.criteria.duration = changesObj.durationStay.currentValue;
            }

            if (changesObj.noGuests && changesObj.noGuests.currentValue) {

                vm.criteria.guests = changesObj.noGuests.currentValue;
            }
        };

        vm.$onInit = function () {
            var url_string = window.location.href;
            var urr = new URL(url_string);
            var dateArray = urr.searchParams.get('date').split('-');

            vm.criteria.duration = Number(urr.searchParams.get('duration'));
            vm.criteria.guests = Number(urr.searchParams.get('guests'));
            vm.criteria.date = urr.searchParams.get('date');
            vm.criteria.pageId = urr.searchParams.get('type');
            vm.criteria.region = vm.criteria.region || null;
            vm.criteria.anyTimeId = urr.searchParams.get('anyTimeBookingId');
            vm.criteria.camcSiteId = urr.searchParams.get('camcSiteId');

            vm.selectedOption = vm.location;

            vm.durationCopy = angular.copy(vm.duration);

            filterDuration(vm.durationCopy, vm.criteria.type);

            if (vm.date == "undefined" || vm.date == "null" || vm.date == undefined) {

                vm.date = "";
            }

            vm.isThin = true;

            $('#thin-search-date').datepicker("option", "defaultDate", new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`));

            console.log(vm);

            getRegionLocationMenu(vm.siteType);
        };

        vm.changeLargeUp = function (isHomePage) {

            vm.largeUp = isHomePage ? "5" : "6";

            return "large-up-" + vm.largeUp;
        };

        vm.changeHomeClass = function (isHomePage) {

            if (isHomePage) {
                vm.HomeClass = "home-search";
            }

            return vm.HomeClass;
        };

        vm.toArray = function (num) {

            return new Array(num);
        };

        vm.isFormValid = function () {

            var date = !isFormFieldInvalid('date');
            var duration = !isFormFieldInvalid('duration');
            var guests = !isFormFieldInvalid('guests');

            return date && duration && guests;
        };

        vm.onSubmitSearch = function () {

            vm.isFormSubmitted = true;

            if (!vm.isFormValid()) return;

            $window.location.href = window.location.pathname + "?type=" + vm.criteria.pageId + "&date=" + vm.criteria.date + "&duration=" + vm.criteria.duration + "&guests=" + vm.criteria.guests + "&anyTimeBookingId=" + vm.criteria.anyTimeId + "&camcSiteId=" + vm.criteria.camcSiteId + "#accommodation-results-dynamic";
        };

        vm.changeTab = function (value, tab) {

            vm.selectedOption = null;
            vm.criteria.pageId = null;
            vm.criteria.region = null;
            vm.criteria.regionId = null;
            vm.criteria.anyTimeId = null;
            vm.criteria.camcSiteId = null;

            vm.criteria.type = value;

            if (value != '0') {

                if (tab == 'Glamping') {
                    vm.campingIsActive = false;
                }
                else if (tab == 'Camping') {
                    vm.largeUp = "4";
                    vm.campingIsActive = true;
                }

                filterDuration(vm.durationCopy, vm.criteria.type);

                getRegionLocationMenu(tab);
            }
        };

        function getRegionLocationMenu(type) {

            return searchService.getRegionLocations(type)
                .then(function (result) {

                    vm.menuData = result.data;

                    return result.data;
                });
        }

        function selectOption(item) {

            $('.location-dropdown-initial').parent().addClass("selected");

            if (item.region.name !== undefined) {
                vm.criteria.pageId = null;
                vm.criteria.anyTimeId = null;
                vm.selectedOption = item.region.name;
                vm.criteria.region = item.region.name;
                vm.criteria.regionId = item.region.id;
            }
            else {
                vm.criteria.region = null;
                vm.selectedOption = item.name;
                vm.criteria.pageId = item.pageId;
                vm.criteria.anyTimeId = item.anytimeBookingSiteId;
                vm.criteria.camcSiteId = item.camcSiteId;
            }
        }

        function toggleMenu() {

            var el = angular.element('#location_drop_down');

            if (el.hasClass('ng-hide')) {
                el.removeClass('ng-hide');
            }
            else {
                el.addClass('ng-hide');
            }
        }

        function filterDuration(array, type) {

            vm.newDuration = [];

            if (type == globalsService.anytimeGlampingCategoryId) {

                for (var i = array.length - 1; i >= 0; --i) {
                    if (array[i].value == 1) {
                        array.splice(i, 1);
                    }
                }

                vm.newDuration = array;
            }
            else if (type == globalsService.anytimeCampingCategoryId) {

                vm.newDuration = vm.duration;
            }
            else {
                vm.newDuration = vm.durationCopy;
            }

            return vm.newDuration;
        }

        function isFormFieldInvalid(fieldName) {

            if (!vm.thinSearchForm[fieldName]) return true;

            var value = vm.criteria[fieldName];

            switch (fieldName) {

                case 'type':
                    return value == 0 && vm.isFormSubmitted;
                case 'region':
                    return !vm.selectedOption && vm.isFormSubmitted;
                case 'date':
                    return !value && vm.isFormSubmitted;
                case 'duration':
                    return value == 0 && vm.isFormSubmitted;
                case 'guests':
                    return value == 0 && vm.isFormSubmitted;
                default:
                    return true && vm.isFormSubmitted;
            }
        }

        function updateFoundation() {
            $timeout(function () {
                $element.find('[data-toggler]').foundation();
            }, 100);
        }
    }
})();
