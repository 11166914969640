﻿(function SocialBar() {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 500 && !$(".socialbar").hasClass("stay-closed")) {
            $(".socialbar").removeClass("closed");
            $(".socialbar").addClass("scroll");
        }
    });

    $(".minimise").click(function () {
        $(".socialbar").toggleClass("closed");
        $(".socialbar").toggleClass("stay-closed");
        setCookie("SocialCheck", true, 1);
    });

    $(document).ready(function () {
        checkCookie();
    });

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie() {
        var isClosed = getCookie("SocialCheck");
        if (isClosed !== "") {
            $(".socialbar").addClass("stay-closed");
        }
    }

})();