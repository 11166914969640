﻿(function () {
    'use strict';

    angular
        .module('app')
        .directive('rebindDirective', rebindDirective);

    rebindDirective.$inject = ['$window', '$timeout'];

    function rebindDirective($window, $timeout) {
        // Usage:
        //     <rebindDirective></rebindDirective>
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'EA',
        };
        return directive;

        function link(scope, element, attrs) {

            if (scope.$last) {

                $(document).foundation();
            }
        }
    }
})();