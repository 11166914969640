﻿angular.module('app').component('addReviewPage', {

    bindings: {
        pageData: '<',
        onCreateReview: '&'
    },

    controllerAs: 'vm',

    controller: function ($timeout, toastService, reviewsService, searchService) {

        var vm = this;

        vm.maxength = 2000;

        vm.accomodationTypes = [];
        vm.accomodationTypeOptions = [];

        vm.formData = {};
        vm.locationData = {};

        vm.$onInit = function () {

            resetForm();
            getAccomodationTypes();
            getRegionLocationMenu('Glamping');
            getRegionLocationMenu('Camping');
        }

        vm.isFormFieldInvalid = isFormFieldInvalid;
        vm.onStarRatingEdit = onStarRatingEdit;
        vm.onSubmitReview = onSubmitReview;
        vm.onSelectAccomodationType = onSelectAccomodationType;
        vm.onSelectLocation = onSelectLocation;
        vm.toggleMenu = toggleMenu;

        function onStarRatingEdit(rating) {

            $timeout(function () {
                vm.formData.rating = rating;
            }, 0);
        }

        function onSubmitReview() {

            var form = vm.formAddReview;

            vm.isFormSubmitted = true;

            // Validate form.
            var response = grecaptcha.getResponse();
            if (response.length == 0) {
                toastService.error('Please complete the reCAPTCHA check.', 'Something went wrong.');
                return false;
            }
            vm.formData.recaptchaToken = grecaptcha.getResponse();

            if (form.$invalid || vm.formData.accomodationType == 0 || vm.formData.pageId == 0) {
                return false;
            }

            // Create.
            reviewsService.create(vm.formData).then(

                function success(response) {

                    toastService.success('It is awaiting moderation.', 'Thank you for your review!');
                    resetForm();
                },

                function error(response) {
                    toastService.error(response.data.message, 'Something went wrong.');
                    resetRecaptcha();
                }
            );
        }

        function isFormFieldInvalid(fieldName) {

            return vm.formAddReview[fieldName].$invalid && vm.isFormSubmitted; //&& !vm.formAddReview[fieldName].$pristine;
        }

        function onSelectAccomodationType() {

            // Force a refresh of locations menu.
            resetSelectLocation();
        }

        function resetSelectLocation() {

            vm.selectedLocation = 'Please select...';
            vm.formData.pageId = 0;
        }

        function onSelectLocation(item) {

            vm.selectedLocation = item.name;
            vm.formData.pageId = item.pageId;
        }

        function toggleMenu() {

            if (vm.formData.accomodationType == 0) {
                alert('Please select an accommodation type.');
                return;
            }

            vm.menuData = (vm.formData.accomodationType == 4) ? vm.locationData.Camping : vm.locationData.Glamping;

            vm.showMenu = !vm.showMenu;

            $timeout(function () {
                $(':focus').blur();
            }, 0);
        }

        function getAccomodationTypes() {

            return reviewsService.getAccomodationTypes().then(

                function success(response) {
                    vm.accomodationTypes = response.data;
                    var accomodationTypeOptions = [];
                    for (var i = 0; i < response.data.length; i++) {
                        var id = i + 1;
                        var name = response.data[i];
                        accomodationTypeOptions.push({ id: id, name: name });
                    }
                    vm.accomodationTypeOptions = accomodationTypeOptions;
                },

                function error(response) {
                }
            );
        }

        function getRegionLocationMenu(type) {

            return searchService.getRegionLocations(type)
                .then(function (result) {

                    console.log('Location data loaded: ' + type);
                    vm.locationData[type] = result.data;

                    return result.data;
                });
        }

        function resetForm() {

            $timeout(function () {
                vm.isFormSubmitted = false;
                vm.formAddReview.$setPristine();
                vm.formData.title = vm.pageData.title;
                vm.formData.authorFirstName = vm.pageData.authorFirstName;
                vm.formData.authorLastName = vm.pageData.authorLastName;
                vm.formData.accomodationType = 0;
                vm.formData.rating = 5;
                vm.formData.comment = vm.pageData.comment;
                vm.formData.authorEmail = vm.pageData.authorEmail;
                resetSelectLocation();
                resetRecaptcha();
            }, 0);
        }

        function resetRecaptcha() {

            if (angular.isUndefined(grecaptcha))
                return;

            grecaptcha.reset();
        }
    },

    templateUrl: './ui/app/reviews/add-review-page.html'
});