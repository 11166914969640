﻿angular.module('app').directive('starRating', StarRatingDirective);

function StarRatingDirective($compile, $timeout) {

    return {
        restrict: 'E',
        scope: {
            reviews: '<',
            isEditable: '<?',
            onEdit: '&?'
        },
        link: link
    };

    function link(scope, element, attrs) {

        scope.isEditable = (typeof scope.isEditable !== 'undefined') ? scope.isEditable : false;
        scope.onEdit = (angular.isFunction(scope.onEdit)) ? scope.onEdit : function () { };

        if (scope.isEditable && !angular.isArray(scope.reviews)) {
            $timeout(function () {

                element.bind('click', function (event) {
                    event.stopPropagation();

                    var children = event.target.parentElement.children;
                    for (var i = 0; i < children.length; i++) {
                        if (children[i] == event.target) {
                            var rating = i + 1;
                            if (rating === scope.reviews.rating) break;

                            if (rating < 1)
                                rating = 1;
                            else if (rating > 5)
                                rating = 5;

                            scope.reviews.rating = rating;
                            scope.onEdit()(rating);
                            createStarRatingView(scope, element, attrs);
                            break;
                        }
                    }
                });
            }, 0);
        }

        var watcher = scope.$watchCollection('reviews',
            function () {
                createStarRatingView(scope, element, attrs);
            }
        );
    }

    function createStarRatingView(scope, element, attrs) {

        var result = getStarRating(scope.reviews);
        var compiled;
        var html = '';

        html += getStars(result.full, 'fas fa-star');
        html += getStars(result.half, 'fad fa-star-half-alt');
        html += getStars(result.empty, 'far fa-star');

        compiled = $compile(html)(scope);
        element.html(compiled);

        var rating = result.full;
        rating += (result.half === 1) ? 0.5 : 0;

        if (angular.isArray(scope.reviews) && scope.reviews.length == 0) {
            element.attr('title', 'Not yet rated');
        } else {
            element.attr('title', rating + ' star rating');
        }

        if (angular.isArray(scope.reviews)) {
            var classNoReviews = 'no-reviews';
            if (scope.reviews.length == 0 && !element.hasClass(classNoReviews)) {
                element.addClass(classNoReviews);
            } else if (scope.reviews.length != 0 && element.hasClass(classNoReviews)) {
                element.removeClass(classNoReviews);
            }
        }

        element.addClass('star-rating');
        if (scope.isEditable) element.addClass('is-editable');
    }

    function getStars(numberOfStars, className) {

        var html = '';
        for (var i = 0; i < numberOfStars; i++) {
            html += '<span class="' + className + '"></span>';
        }

        return html;
    }

    function getStarRating(review) {

        var full = 0, half = 0, empty = 0, maximum = 5;

        if (angular.isArray(review)) {
            // An overall rating.

            var reviews = review;

            var totalReviews = reviews.length;
            var totalRatings = 0;
            for (var i = 0; i < totalReviews; i++) {
                totalRatings += reviews[i].rating;
            }
            var maxPossibleRatings = totalReviews * maximum;

            var decimal = totalRatings / maxPossibleRatings;
            decimal = isNaN(decimal) ? 0 : decimal;

            var rating = Math.round( (decimal * maximum) * 10) / 10;

            full = Math.floor(rating);
            half = (rating - full > 0) ? 1 : 0;
            empty = maximum - full - half;

            //console.log('getStarRating', {
            //    'totalRatings': totalRatings, 'maxPossibleRatings': maxPossibleRatings, 'rating': rating,
            //    'decimal': decimal, 'full': full, 'half': half, 'empty': empty
            //});
        }

        else if (angular.isUndefined(review) || angular.isUndefined(review.rating)) {
            // No review provided.
            empty = maximum;
        }

        else {
            // A single rating.
            full = review.rating;
            empty = maximum - full;
        }

        return {
            full: full,
            half: half,
            empty: empty,
            maximum: maximum
        };
    };
}