﻿angular.module('app').controller('SearchController', SearchController);

function SearchController($element, searchService) {

    var vm = this;

    vm.locations = [];
    vm.maxDuration = 21;

    vm.toArray = function (num) {
        return new Array(num);
    };

    vm.onSubmitSearch = function () {

        var criteria = {};
    };

    vm.onLocationChange = function (item) {
    };

    function onInit() {

        searchService.getAllLocations().then(

            function success(response) {

                console.log(response);
                vm.locations = response.data;
            },

            function error(response) {

                console.log(response);
            });
    }

    onInit();
}