﻿angular.module('app').component('anytimeSearchResult', {

    bindings: {
        item: '<',
        criteria: '<'
    },

    controllerAs: 'vm',

    controller: function ($element, $filter, $timeout, globalsService) {

        var vm = this;

        var paths = globalsService.anytimebooking;

        vm.$onInit = function () {
            updateFoundation();
        };

        vm.thumbnailPath = function () {
            return paths.domain + 'unit/thumbnail/' + vm.item.unitId;
        };

        vm.bookingUrl = function () {
            var i = vm.item;
            return paths.domain + paths.bookingUrl + i.categoryId + '/' + i.unitId + '/' + i.startDate + '/' + i.duration;
        };

        vm.locationUrl = function () {

            return 'locations/' + makeUrlFriendly(vm.item.siteName);
        };

        vm.accomodationUrl = function () {
            return 'accommodation/' + makeUrlFriendly(vm.item.categoryName);
        };

        vm.popUpType = function () {

            var url = ""
            switch (vm.item.categoryName) {

                case "Glamping Pods":
                    url = "/glamping/glamping-pod/"
                    break;
                case "Camping Pods":
                    url = "/glamping/camping-pod/"
                    break;
                case "Yurts":
                    url = "/glamping/yurts/"
                    break;
                case "Safari Tents":
                    url = "/glamping/safari-tents/"
                    break;
                default:
                    break;
            }

            return url;
        };

        vm.smoothScroll = function () {
            if (document.getElementById('anytimeScroll'))
                document.getElementById('anytimeScroll').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                });
                //scrollToTargetAdjusted(document.getElementById('anytimeScroll'), 0);
        };

        vm.formattedDate = function () {
            return $filter('date')(vm.criteria && vm.criteria.date ? vm.criteria.date : vm.item.startDate, 'EEEE d MMM y');
        };

        vm.getOccupancy = function () {
                var occupancy = "";
                switch (vm.item.categoryName.trim().toLowerCase()) {
                    case "glamping pods":
                        occupancy = "2 adults and 2 children";
                        break;
                    case "glamping cabins":
                        occupancy = "4 adults and 2 children";
                        break;
                    case "yurts":
                        occupancy = "2 adults and 2 children";
                        break;
                    case "glamping pods - universally accessible":
                        occupancy = "2 adults and 1 child";
                        break;
                    case "glamping cabins - universally accessible":
                        occupancy = "3 adults and 2 children";
                        break;
                    case "safari tents":
                        occupancy = "5 adults";
                        break;
                    case "camping pods":
                        occupancy = "2 adults";
                        break;
                    default:
                        occupancy = vm.item.occupancy + " people";
                        break;

                }
                return occupancy;
        };

        function makeUrlFriendly(str) {
            return str.trim().replace(' ', '-').toLowerCase();
        }

        function updateFoundation() {
            $timeout(function () {
                $element.find('[data-equalizer]').foundation();
            }, 200);
        }

        function scrollToTargetAdjusted(element, offset) {
            if (!element) return false;
            var headerOffset = offset ? offset : 45;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    },

    templateUrl: './ui/app/anytime-search/anytime-search-result.html'
});