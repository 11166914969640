﻿angular.module('app').component('reviewsSummary', {

    bindings: {
        reviews: '<'
    },

    controllerAs: 'vm',

    controller: function (reviewsService) {

        var vm = this;

        vm.$onChanges = function (changesObj) {

            if (changesObj.reviews && changesObj.reviews.currentValue) {

                vm.reviews = changesObj.reviews.currentValue;
            }
            else {
                vm.reviews = [];
            }
        }

        vm.getReviewsSummary = function () {

            var suffix = (vm.reviews.length == 1) ? '' : 's';
            return vm.reviews.length + ' Review' + suffix;
        };
    },

    templateUrl: './ui/app/reviews/reviews-summary.html'
});