﻿(function () {
    const Mappy = require('@googlemaps/js-api-loader');

    angular.module('app').component('locationsListingComponent', {
        restrict: 'E',
        bindings: {
            type: '@',
            guests: '@',
            duration: '@',
            date: '@',
            region: '@',
            regionId: '<',
            accType: '@',
            resMessage: '@'
        },
        templateUrl: './ui/app/components/locations-listing-component.html',
        controller: controller,
        controllerAs: 'vm'
    });

    controller.$inject = ['$log', 'globalsService', 'searchService', '$element', '$window', '$timeout'];

    function controller($log, globalsService, searchService, $element, $window, $timeout) {

        var vm = this;

        vm.accOptions = {
            GlampingPods: { name: 'Classic Glamping Pods', value: 1 },
            DeluxeGlampingPods: { name: 'Deluxe Glamping Pods', value: 12 },
            Yurts: { name: 'Classic Yurts', value: 2 },
            CampingPods: { name: 'Camping Pods', value: 3 },
            TentPitches: { name: 'Tent Pitches', value: 4 },
            GlampingPodsUA: { name: 'Glamping Pods UA', value: 6 },
            GlampingCabins: { name: 'Classic Glamping Cabins', value: 7 },
            DeluxeGlampingCabins: { name: 'Deluxe Glamping Cabins', value: 13 },
            GlampingCabinsUA: { name: 'Glamping Cabins UA', value: 8 },
            UniAccss: { name: 'Universally Accessible', value: 9 },
            SafariTents: { name: 'Classic Safari Tents', value: 10 },
            PremiumDeluxeSafariTents: { name: 'Premium Deluxe Safari Tents', value: 14 },
            DeluxeSafariTents: { name: 'Deluxe Safari Tents', value: 15 },
            SuperDeluxeSafariTents: { name: 'Super Deluxe Safari Tents', value: 16 },
            WoodenYurts: { name: 'Wooden Yurts', value: 11 },
            AllGlamping: { name: 'All Glamping', value: 20 },
            AllGlampingPods: { name: 'Glamping Pods', value: 21 },
            AllGlampingCabins: { name: 'Glamping Cabins', value: 22 },
            AllSafariTents: { name: 'Safari Tents', value: 23 },
            AllYurts: { name: 'Yurts', value: 24 },

        };

        vm.campingValues = [4]; //update if you want to show in camping filter
        vm.glampingArray = [vm.accOptions.CampingPods,
            vm.accOptions.GlampingPods,
            vm.accOptions.GlampingCabins,
            vm.accOptions.Yurts,
            vm.accOptions.SafariTents,
            vm.accOptions.UniAccss,
            vm.accOptions.WoodenYurts,
            vm.accOptions.DeluxeGlampingPods,
            vm.accOptions.DeluxeGlampingCabins,
            vm.accOptions.PremiumDeluxeSafariTents,
            vm.accOptions.DeluxeSafariTents,
            vm.accOptions.SuperDeluxeSafariTents,
            vm.accOptions.AllGlampingPods,
            vm.accOptions.AllGlampingCabins,
            vm.accOptions.AllSafariTents,
            vm.accOptions.AllYurts
        ]; //Update to handle what is checked and unchecked with All Glamping


        vm.accommodation = [
            vm.accOptions.AllGlamping,
            vm.accOptions.CampingPods,
            vm.accOptions.AllGlampingPods,
            //vm.accOptions.DeluxeGlampingPods,
            vm.accOptions.AllGlampingCabins,
            //vm.accOptions.DeluxeGlampingCabins,
            vm.accOptions.TentPitches,
            vm.accOptions.AllYurts,
            //vm.accOptions.WoodenYurts,
            vm.accOptions.UniAccss,
            vm.accOptions.AllSafariTents
            //vm.accOptions.PremiumDeluxeSafariTents,
            //vm.accOptions.DeluxeSafariTents,
            //vm.accOptions.SuperDeluxeSafariTents
        ];

        vm.accTypes = [];
        vm.regions = [];

        vm.searchModel = {
            accomodationTypes: vm.accTypes,
            regions: []
        };

        vm.checked = checked;
        vm.regionChecked = regionChecked;
        vm.goToLocation = goToLocation;
        vm.pluralise = pluralise;
        vm.switchV = switchView;
        vm.mapView = false;
        vm.markers = [];
        vm.map = "";
        vm.showToggles = false;
        vm.toggleFilters = toggleFilters;
        vm.goToMap = goToMap;
        vm.isGlamp = isGlamp;

        vm.$onInit = function () {

            vm.type = vm.type || null;
            vm.guests = vm.guests || 0;
            vm.duration = vm.duration || 0;
            vm.date = vm.date || null;

            getRegions().then(function (result) {

                if (vm.region !== "undefined" && vm.region && vm.region != "null") {

                    var regionId = "region" + vm.regionId;

                    $timeout(function () {
                        updateCheckLabel(regionId);
                    }, 500);
                }
            });

            if (vm.regionId !== "undefined" && vm.regionId)
                vm.searchModel.regions.push(vm.regionId);

            var isAnytimeGlamping = vm.type == globalsService.anytimeGlampingCategoryId;

            if (vm.accType == "allglampingpods" || isAnytimeGlamping) {
                addSelectedAccType(vm.accOptions.GlampingPods);
                addSelectedAccType(vm.accOptions.DeluxeGlampingPods);
                addSelectedAccType(vm.accOptions.GlampingPodsUA);
                addSelectedAccType(vm.accOptions.AllGlampingPods);
            }
            if (vm.accType == "allyurts" || isAnytimeGlamping) {
                addSelectedAccType(vm.accOptions.Yurts);
                addSelectedAccType(vm.accOptions.WoodenYurts);
                addSelectedAccType(vm.accOptions.AllYurts);
            }
            if (vm.accType == "campingpods" || isAnytimeGlamping) {
                addSelectedAccType(vm.accOptions.CampingPods);
            }
            if (vm.accType == "allsafaritents" || isAnytimeGlamping) {
                addSelectedAccType(vm.accOptions.SafariTents);
                addSelectedAccType(vm.accOptions.PremiumDeluxeSafariTents);
                addSelectedAccType(vm.accOptions.DeluxeSafariTents);
                addSelectedAccType(vm.accOptions.SuperDeluxeSafariTents);
                addSelectedAccType(vm.accOptions.AllSafariTents);
            }
            if (vm.accType == "allglampingcabins" || isAnytimeGlamping) {
                addSelectedAccType(vm.accOptions.GlampingCabins);
                addSelectedAccType(vm.accOptions.GlampingCabinsUA);
                addSelectedAccType(vm.accOptions.DeluxeGlampingCabins);
                addSelectedAccType(vm.accOptions.AllGlampingCabins);
            }
            if (vm.accType == "universallyaccessible" || isAnytimeGlamping) {
                addSelectedAccType(vm.accOptions.GlampingPodsUA);
                addSelectedAccType(vm.accOptions.GlampingCabinsUA);
                addSelectedAccType(vm.accOptions.UniAccss);
            }

            if (vm.type == globalsService.anytimeCampingCategoryId) {

                addSelectedAccType(vm.accOptions.TentPitches);
            }

            addSelectedAccType(vm.accOptions.AllGlamping);
            vm.glampingArray.forEach(gla => {
                addSelectedAccType(gla)
            })

            getLocations(vm.searchModel);
            updateFoundation();
        };

        function checked($event, item, shouldSearch) {

            if ($event.currentTarget.checked == true) {
                if (item.name == vm.accOptions.AllGlamping.name) {
                    vm.glampingArray.forEach(gla => {
                        addSelectedAccType(gla)
                    })
                }else if (!findById(item.name, vm.accTypes))
                    vm.accTypes.push(item.name);
            }
            else {
                if (item.name == vm.accOptions.AllGlamping.name) {
                    vm.glampingArray.forEach(gla => {
                        removeSelectedAccType(gla);
                        deCheckLabel("accType" + gla.value);
                    })
                }
               
                else if (item.name == vm.accOptions.AllGlampingCabins.name) {
                    removeSelectedAccType(vm.accOptions.GlampingCabins);
                    removeSelectedAccType(vm.accOptions.GlampingCabinsUA);
                    removeSelectedAccType(vm.accOptions.DeluxeGlampingCabins);
                }

                else if (item.name == vm.accOptions.AllGlampingPods.name) {
                    removeSelectedAccType(vm.accOptions.GlampingPods);
                    removeSelectedAccType(vm.accOptions.GlampingPodsUA);
                    removeSelectedAccType(vm.accOptions.DeluxeGlampingPods);
                }

                else if (item.name == vm.accOptions.UniAccss.name) {
                    removeSelectedAccType(vm.accOptions.UniAccss);
                    removeSelectedAccType(vm.accOptions.GlampingCabinsUA);
                    removeSelectedAccType(vm.accOptions.GlampingPodsUA);
                }

                else if (item.name == vm.accOptions.AllYurts.name) {
                    removeSelectedAccType(vm.accOptions.Yurts);
                    removeSelectedAccType(vm.accOptions.WoodenYurts);
                    removeSelectedAccType(vm.accOptions.AllYurts);
                }
                    
                else if (findById(item.name, vm.accTypes)) {
                    removeSelectedAccType(item);
                }                
            }

            let ourSearch = [];
            vm.glampingArray.forEach(gla => {
                vm.searchModel.accomodationTypes.includes(gla.name) ? ourSearch.push(true) : ourSearch.push(false)
            });
            ourSearch.every(Boolean) ? toggleIndeterminate(false) : (ourSearch.includes(true) ? toggleIndeterminate(true) : toggleIndeterminate(false) );
            
            removeSelectedAccType(vm.accOptions.AllGlamping);

            if (shouldSearch == true) {

                console.log('searchModel', vm.searchModel);

                getLocations(vm.searchModel);
            }
        }

        function addSelectedAccType(item) {

            if (!findById(item.name, vm.accTypes)) {
                vm.accTypes.push(item.name);
                updateCheckLabel("accType" + item.value);
            }
        }

        function removeSelectedAccType(item) {

            var index = vm.accTypes.indexOf(item.name);

            if (index > -1)
                vm.accTypes.splice(vm.accTypes.indexOf(item.name), 1);
        }

        function regionChecked($event, region) {

            if ($event.currentTarget.checked == true) {

                if (!findById(region.id, vm.searchModel.regions))
                    vm.searchModel.regions.push(region.id);
            }
            else {

                if (findById(region.id, vm.searchModel.regions))
                    vm.searchModel.regions.splice(vm.searchModel.regions.indexOf(region.id), 1);
            }

            getLocations(vm.searchModel);
        }

        function getLocations(model, siteID) {

            console.log('searchModel', model);

            return searchService.getLocationsByCriteria(model)
                .then(function (result) {

                    vm.locations = result.data;
                    if(vm.mapView) loadMap(true, siteID);
                    return result;
                });
        }

        function updateCheckLabel(elementId) {

            angular.element(document).ready(function () {

                var el = document.getElementById(elementId);
                if (el) {
                    el.checked = true;
                    $("#" + elementId).parent().addClass('checked');
                }
            });
        }

        function deCheckLabel(elementId) {
            angular.element(document).ready(function () {

                var el = document.getElementById(elementId);
                if (el) {
                    el.checked = false;
                    $("#" + elementId).parent().removeClass('checked');
                }
            });
        }

        function toggleIndeterminate(bool) {
            angular.element(document).ready(function () {
                var el = document.getElementById('accType20');
                if (el) {
                    bool ? $("#accType20").parent().addClass('inter') : $("#accType20").parent().removeClass('inter');
                }
            })
        }

        function findById(id, array) {

            return _.contains(array, id);
        }

        function getRegions() {

            return searchService.getRegions()
                .then(function (result) {

                    vm.regions = result.data;

                    return result;
                });
        }

        function goToLocation(location) {

            $window.location.href = location.linkToPage + "?type=" + vm.type + "&date=" + vm.date + "&duration=" + vm.duration + "&guests=" + vm.guests + "&anyTimeBookingId=" + location.anyTimeBookingId;
        }

        function pluralise(count, suffix) {

            suffix = angular.isString(suffix) ? suffix : 's';

            return count == 1 ? '' : suffix;
        }

        function updateFoundation() {

            $timeout(function () {
                $element.find('[data-equalizer]').foundation();
            }, 200);
        }

        function isGlamp(value) {
            if (vm.campingValues.includes(value)) {
                return true
            }
            return false
        }

        // Start of Map functions
        function goToMap(location) {
            //vm.map == false ? switchView(false, location.camcSiteId) : switchView(true, location.camcSiteId);
            switchView(location.camcSiteId);
        }

        function switchView(siteID){
            vm.mapView = !vm.mapView;
            vm.mapView ? loadMap(false, siteID) : getLocations(vm.searchModel, siteID);
        }

        function toggleFilters(){
            if(window.innerWidth > 760) return vm.showToggles = false;
            vm.showToggles = !vm.showToggles;
        }

        function loadMap(redraw, siteID){

            if(redraw){
                // Wipe existing markers from map
                for (var i = 0; i < vm.markers.length; i++) {
                    vm.markers[i].setMap();
                }

                // Clear the array and populate markers with new filters
                vm.markers = []; populateMarkers();
                // EXIT
                return;
            }

            function buildMap(mapOptions){
                vm.map = new google.maps.Map(document.getElementById("map"), mapOptions);
                populateMarkers();
            }

            function buildReviews(reviews){
                if(!reviews) return `<div class="map-locator__star-wrap--no-reviews star-rating"><span class="far fa-star"></span><span class="far fa-star"></span><span class="far fa-star"></span><span class="far fa-star"></span><span class="far fa-star"></span></div>`;

                let ratings = [];
                for(var i = 0; i < reviews.length; i++){
                    ratings.push(reviews[i].rating)
                }                   
                let ratingsAverage = ratings.reduce((a,v,i)=>(a*i+v)/(i+1));
                ratingsLoop = Math.round(ratingsAverage*2)/2;
                
                let full = `<span class="fas fa-star"></span>`;
                let half = `<span class="fad fa-star-half-alt"></span>`;

                function buildStars(num){
                    let stars = "";
                    for(num; num > 0; num--){
                        if(num >= 1){
                            stars += full;
                        }else if(num < 1){
                            stars += half;
                        }
                    }
                    return stars;
                }

                let wrap = `<div class="map-locator__star-wrap star-rating">${buildStars(ratingsLoop)}</div>`;
                
                return wrap;
            }

            function buildPrice(loc) {
                let glampArray = [Number(loc.glampingPodPrice), Number(loc.glampingCabinPrice), Number(loc.safariTentPrice), Number(loc.yurtPrice), Number(loc.campingPodPrice)];
                let lowestGlamp = Math.min(...glampArray.filter(Boolean));  
                if (isFinite(lowestGlamp) && lowestGlamp > 0 ) {
                    return `Glamping from &pound;${lowestGlamp}`
                } else if (Number(loc.tentPitchPrice) > 0) {
                    return `Camping from &pound;${loc.tentPitchPrice}`
                } else {
                    return "Contact for price"
                }
            }

            // Split this part out so we can call it when data is filtered instead of recreating the whole map
            function populateMarkers(){
                var marker, i;

                var offset = window.innerWidth >= 760 ? new google.maps.Size(165, 200) : new google.maps.Size(0, 0);

                var infowindow = new google.maps.InfoWindow({ minWidth: 262, maxWidth: 262, pixelOffset: offset});

                let locs = vm.locations;

                for (i = 0; i < locs.length; i++) {

                    // If they aint got the lat/lng, continuing would be wrong
                    if(locs[i].latitude.length == 0 || locs[i].longitude.length == 0) continue;

                    // Build the marker
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(locs[i].latitude, locs[i].longitude),
                        map: vm.map,
                        icon: {
                            scaledSize: new google.maps.Size(30, 44),
                            url: '/static/ui/css/img/map/red-marker.svg'
                        }
                    });

                    // Assign siteid as a uid to the marker incase we need to find it in the array later 
                    marker.set('uid', locs[i].camcSiteId);

                    // Build the infowindow template
                    let contentString =
                        `<div class="map-locator__window">
                            <div id="map-locator__close"></div>
                            <img id="map-locator__image" src="${locs[i].image.imageUrl}">
                            <div id="map-locator__body">
                                <p class="map-locator__name">${locs[i].listingName}</p>
                                <div class="map-locator__icons">
                                    ${locs[i].tentPitchCount >= 1 ? 
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-tents.svg" alt="Icon - Tents">
                                            <p class="map-locator__pitch-count">${locs[i].tentPitchCount} Tent pitches</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].glampingPodCount >= 1 ? 
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-glamping-pod.svg" alt="Icon - Glamping Pods">
                                            <p class="map-locator__pitch-count">${locs[i].glampingPodCount} Classic Glamping Pods</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].campingPodCount >= 1 ? 
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-camping-pods.svg" alt="Icon - Glamping Pods">
                                            <p class="map-locator__pitch-count">${locs[i].campingPodCount} Camping Pods</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].glampingCabinCount >= 1 ?
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-glamping-cabin.svg" alt="Icon - Glamping Cabin">
                                            <p class="map-locator__pitch-count">${locs[i].glampingCabinCount} Classic Glamping Cabin</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].yurtCount >= 1 ?
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-yurts.svg" alt="Icon - Yurts">
                                            <p class="map-locator__pitch-count">${locs[i].yurtCount} Classic Yurts</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].woodenYurtCount >= 1 ?
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-yurts.svg" alt="Icon - Yurts">
                                            <p class="map-locator__pitch-count">${locs[i].woodenYurtCount} Wooden Yurts</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].glampingPodUniAccessCount >= 1 ?
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-glamping-pod-ua.svg" alt="Icon - Glamping Pod - Universally Accessible">
                                            <p class="map-locator__pitch-count">${locs[i].glampingPodUniAccessCount} Classic Glamping Pod - UA</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].glampingCabinUniAccessCount >= 1 ?
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-glamping-cabin-ua.svg" alt="Icon - Glamping Cabin - Universally Accessible">
                                            <p class="map-locator__pitch-count">${locs[i].glampingCabinUniAccessCount} Classic Glamping Cabin - UA</p>
                                        </div>` : ``
                                    }
                                    ${locs[i].safariTentCount >= 1 ?
                                        `<div class="map-locator__icon-row">
                                            <img src="/static/ui/css/img/icon-safari-tent.svg" alt="Icon - Safari Tents">
                                            <p class="map-locator__pitch-count">${locs[i].safariTentCount} Classic Safari Tent</p>
                                        </div>` : ``
                                    }
                                </div>                                
                                <div class="map-locator__review-wrap">
                                    ${locs[i].reviews.reviews.length >= 1 ? buildReviews(locs[i].reviews.reviews) : buildReviews(false)}
                                    <p class="map-locator__review-text">${locs[i].reviews.reviews.length} Reviews</p>
                                </div>
                                <p class="map-locator__price">${buildPrice(locs[i])} per night</p>
                                <div class="map-locator__cta-row">
                                    <a class="btn" href="${locs[i].linkToPage}">View details</a>
                                </div>
                            </div>
                        </div>`;

                    // Attach click function to marker
                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        return function () {
                            infowindow.setContent(contentString);                            
                            infowindow.open(vm.map, marker);
                            setTimeout(()=>{
                                if (document.getElementsByClassName('gm-style-iw-container').length === 0) {
                                    document.getElementsByClassName('gm-style-iw-t')[0].parentElement.classList.add('gm-style-iw-container');
                                }
                            }, 500);                            
                        }
                    })(marker, i));

                    // If we have a siteID passed in, lets go to it on the map and open
                    if(siteID && siteID == locs[i].camcSiteId){
                        vm.map.setZoom(15);
                        vm.map.setCenter(marker.getPosition());
                        infowindow.setContent(contentString);
                        infowindow.open(vm.map, marker);
                        setTimeout(()=>{
                            if (document.getElementsByClassName('gm-style-iw-container').length === 0) {
                                document.getElementsByClassName('gm-style-iw-t')[0].parentElement.classList.add('gm-style-iw-container');
                            }
                            document.getElementById('mapContainer').scrollIntoView();
                        }, 500);
                    }
                    
                    vm.markers.push(marker);
                }
            }

            // Promise once library has been loaded in
            // PROD: AIzaSyBnTreH8tBe46JIJyslZ-ocUK80wbiYraI(OLD) AIzaSyDEX3wTD75I3DlQ9uFFw-nWsvtFRWOeBCg(NEW)
            // DEV: AIzaSyD8tZOEmwjqINYYxV52z_ACzM8SfunE07o
            new Mappy.Loader({
                apiKey: "AIzaSyDEX3wTD75I3DlQ9uFFw-nWsvtFRWOeBCg",
                version: "weekly"
            }).load().then(() => {

                // Map options used on init, ennit
                var mapOptions = {
                    center: {
                        lat: 54.3473786,
                        lng: -4.5086644
                    },
                    controlSize: 30,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                    zoom: 6,
                    styles: [
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#93d2a4"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#afd3b9"
                                }
                            ],
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "on",
                                    "color": "#fde6a8"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#91b4e7"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [
                                { 
                                    "visibility": "off" 
                                }
                            ],
                          },
                    ]
                };
                buildMap(mapOptions);
            })
            .catch(e => {
                console.warn('Error in Gmaps Promise')
            });
        }
    }
})();
