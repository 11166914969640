﻿/**
 * Main AngularJS app.
 *
 * Uses AngularJS v1.6.7
 *
 * Requires installation of dependencies with bower and npm prior to build.
 * Requires building with gulpfile to compile client files.
 *
 * App follows the Angular 1 Style Guide.
 * @see https://github.com/johnpapa/angular-styleguide/blob/master/a1/README.md
 *
 */
require('./app.module');

angular.element(document).ready(function () {

    // Manually bootstrap app.
    // Provides flexibility to perform operations before AngularJS compiles page.
    angular.bootstrap(document, ['app'], {
        strictDi: true
    });

    console.log('App Ready: ' + AppHelper.Environment.Name);
});

require('./app.custom'); 